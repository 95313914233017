<template>
  <div class="wrapper">
    <div class="header">
      <div class="name">年度筛选</div>
      <el-radio-group v-model="annualFilter">
        <el-radio-button :label="year" :name="year" v-for="year in yearsOption" :key="year">
          {{ year }}
        </el-radio-button>
      </el-radio-group>
    </div>
    <div class="body">
      <div class="tab-content">
        <YinhuashuiTable class="table" :table="table" ref="YinhuashuiTable" :key="annualFilter">
        </YinhuashuiTable>
      </div>
    </div>
  </div>
</template>

<script>
import YinhuashuiTable from "./YinhuashuiTable";
import { getContSummaryYearList, getStampTaxYearList } from "../../../../api/yinhuashui";

export default {
  name: "YinhuashuiTableSwitcher",
  components: {
    YinhuashuiTable,
  },
  data() {
    return {
      annualFilter: "",
      yearsOption: [],
      table: [],
    };
  },
  mounted() {
    this.getYearsOptions();
  },
  methods: {
    /**
     * 获取印花统计年份选项
     */
    async getYearsOptions() {
      const res = await getStampTaxYearList({ programId: this.$route.params.programId });
      this.yearsOption = res.data;
      this.annualFilter = this.yearsOption[0];
    },
    async getYearlyYinhuashuiTable(year) {
      const res = await getContSummaryYearList({ programId: this.$route.params.programId, year });
      this.table = res.data;
    },
  },
  watch: {
    annualFilter: {
      immediate: false,
      handler(val) {
        this.getYearlyYinhuashuiTable(val);
      },
    },
  },
};
</script>

<style lang="stylus" scoped>
.table {
  width 100%;
  padding 0
  box-shadow: 0px 4px 8px 0px #eee
  border-radius 8px
  overflow hidden
}

.wrapper {
  .header {
    border-bottom: 1px solid #eee;
    padding-bottom 15px
    display flex
    align-items center;

    .name {
      font-weight bold
      margin-right 20px
    }
  }
}
</style>
